<template>
  <div>
    <b-overlay :show="loading">
      <b-card class="overflow-hidden" bg-variant="primary">
        <b-row no-gutters>
          <b-col md="6">
            <div class="">
              <h3 class="text-white">{{ data.name }}</h3>
              <div class="d-flex align-items-center mt-2">
                <div class="d-flex align-items-center text-body ml-2">
                  <feather-icon icon="ClockIcon" class="mr-50 text-white" />
                  <small class="font-weight-bold text-white">Waktu Berakhir</small>
                </div>
              </div>
              <div class="d-flex align-items-center mt-1">
                <div class="d-flex align-items-center text-body">
                  <small class="font-weight-bold text-white">{{ getTanggal(data.info_start) }} WIB</small>
                </div>
                <div class="d-flex align-items-center text-body ml-1">
                  <small class="font-weight-bold text-white">{{ getTanggal(data.info_end) }} WIB</small>
                </div>
              </div>
            </div>
          </b-col>
          <b-col md="6">
            <b-card>
              <b-row class="">
                <b-col>
                  <b-media vertical-align="top" class="mb-2">
                    <template #aside>
                      <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" size="sm" variant="dark" class="btn-icon rounded-circle" disabled>
                        <feather-icon icon="UserIcon" />
                      </b-button>
                    </template>
                    <span>Peserta</span> <br />
                    <b>{{ data.sum_quota }}/{{ data.max_quota }}</b>
                  </b-media>
                </b-col>
                <b-col>
                  <b-media vertical-align="top" class="mb-2">
                    <template #aside>
                      <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" size="sm" variant="dark" class="btn-icon rounded-circle" disabled>
                        <feather-icon icon="ClockIcon" />
                      </b-button>
                    </template>
                    <span>Total Waktu</span> <br />
                    <b> {{ totalTimer }} menit</b>
                  </b-media>
                </b-col>
                <b-col>
                  <b-media vertical-align="top" class="mb-2">
                    <template #aside>
                      <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" size="sm" variant="dark" class="btn-icon rounded-circle" disabled>
                        <feather-icon icon="FileTextIcon" />
                      </b-button>
                    </template>
                    <span>Total Soal</span> <br />
                    <b>{{ data.sum_soal }} Soal</b>
                  </b-media>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-card>
      <b-card :header="'Subtest(' + data.sum_subtest + ')'" header-text-variant="black">
        <b-card-body>
          <div class="" v-if="dataSubtes[0] != null">
            <div class="" v-for="(data, i) in dataSubtes" :key="i">
              <app-collapse v-if="data.name">
                <app-collapse-item :title="data.name">
                  <div class="mt-2" v-for="(item, i) in data.items" :key="i">
                    <b-card no-body class="border">
                      <b-card-body>
                        <div class="d-flex justify-content-between align-items-center">
                          <div class="align-items-center text-body">
                            <h4>
                              <b> {{ item.nama_ujian }}</b>
                            </h4>
                            <span v-if="item.jadwal_id != null">
                              <span v-if="item.hasil && item.status == 'complete'">
                                <feather-icon icon="CalendarIcon" class="mr-50" />
                                <small class="font-weight-bold">{{ getTanggal(item.start_time) }} WIB s/d {{ getTanggal(item.end_time) }} WIB </small>
                                <div class="">
                                  <feather-icon icon="ClockIcon" class="mr-50 text-success" />
                                  <small class="font-weight-bold">Selesai</small>
                                  <feather-icon icon="FileTextIcon" class="mr-50 ml-2 text-success" />
                                  <small class="font-weight-bold">Selesai</small>
                                </div>
                              </span>
                              <span v-else>
                                <feather-icon icon="CalendarIcon" class="mr-50" />
                                <small class="font-weight-bold">{{ getTanggal(item.start_time) }} WIB s/d {{ getTanggal(item.end_time) }} WIB </small>
                                <div class="">
                                  <feather-icon icon="ClockIcon" class="mr-50" />
                                  <small class="font-weight-bold">{{ item.timer }} Menit</small>
                                  <feather-icon icon="FileTextIcon" class="mr-50 ml-2" />
                                  <small class="font-weight-bold">{{ item.sum_soal }} Soal</small>
                                </div>
                              </span>
                            </span>
                            <span v-else>
                              <i class="text-danger">Jadwal Belum ditentukan</i>
                            </span>
                          </div>
                          <div class="d-flex align-items-center text-body">
                            <span v-if="item.jadwal_id">
                              <b-button v-if="item.hasil && item.status == 'complete'" variant="outline-success">Selesai</b-button>
                              <b-button v-else-if="!item.in_schedule" variant="outline-secondary" disabled>Tidak Masuk Jadwal</b-button>
                              <b-button v-else variant="outline-primary" @click="mulaiUjian(item)"> Mulai </b-button>
                            </span>
                            <span v-else>
                              <b-button variant="primary disabled" disabled> Jadwal Belum Ditentukan </b-button>
                            </span>
                            <!-- <b-button variant="outline-primary" @click="mulaiUjian(data)" v-if="data.in_schedule == true"> Mulai </b-button>
                      <b-button variant="outline-primary" @click="mulaiUjian(data)" v-else disabled> Mulai </b-button> -->
                          </div>
                        </div>
                      </b-card-body>
                    </b-card>
                  </div>
                </app-collapse-item>
              </app-collapse>
              <b-card no-body class="border" v-else>
                <b-card-body>
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="align-items-center text-body">
                      <h4>
                        <b> {{ data.nama_ujian }}</b>
                      </h4>
                      <span v-if="data.jadwal_id != null">
                        <span v-if="data.status == 'complete'">
                          <feather-icon icon="CalendarIcon" class="mr-50" />
                          <small class="font-weight-bold">{{ getTanggal(data.start_time) }} WIB s/d {{ getTanggal(data.end_time) }} WIB </small>
                          <div class="">
                            <feather-icon icon="ClockIcon" class="mr-50 text-success" />
                            <small class="font-weight-bold">Selesai</small>
                            <feather-icon icon="FileTextIcon" class="mr-50 ml-2 text-success" />
                            <small class="font-weight-bold">Selesai</small>
                          </div>
                        </span>
                        <span v-else>
                          <feather-icon icon="CalendarIcon" class="mr-50" />
                          <small class="font-weight-bold">{{ getTanggal(data.start_time) }} WIB s/d {{ getTanggal(data.end_time) }} WIB </small>
                          <div class="">
                            <feather-icon icon="ClockIcon" class="mr-50" />
                            <small class="font-weight-bold">{{ data.timer }} Menit</small>
                            <feather-icon icon="FileTextIcon" class="mr-50 ml-2" />
                            <small class="font-weight-bold">{{ data.sum_soal }} Soal</small>
                          </div>
                        </span>
                      </span>
                      <span v-else>
                        <i class="text-danger">Jadwal Belum ditentukan</i>
                      </span>
                    </div>
                    <div class="d-flex align-items-center text-body">
                      <span v-if="data.jadwal_id != null">
                        <b-button v-if="data.status == 'complete'" variant="outline-success">Selesai</b-button>
                        <b-button v-else variant="outline-primary" @click="mulaiUjian(data)"> Mulai </b-button>
                      </span>
                      <span v-else>
                        <b-button variant="primary disabled" disabled> Jadwal Belum Ditentukan </b-button>
                      </span>
                      <!-- <b-button variant="outline-primary" @click="mulaiUjian(data)" v-if="data.in_schedule == true"> Mulai </b-button>
                      <b-button variant="outline-primary" @click="mulaiUjian(data)" v-else disabled> Mulai </b-button> -->
                    </div>
                  </div>
                </b-card-body>
              </b-card>
            </div>
          </div>
          <div v-else>
            <h5 class="text-danger text-center mt-2">Tidak ada Subtest</h5>
          </div>
          <center>
            <span>
              <b-button v-if="is_lihat_hasil" variant="outline-primary" @click="lihatHasil()"> Lihat Hasil </b-button>
            </span>
          </center>
        </b-card-body>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { BOverlay, BCollapse, BButton, VBToggle, BCard, BCardHeader, BCardBody, BCardTitle, BCardImg, BImg, BBadge, BRow, BCol, BMedia } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BOverlay,
    BButton,
    BCard,
    BCardHeader,
    BCollapse,
    BCardBody,
    BCardTitle,
    BCardImg,
    BImg,
    BBadge,
    BRow,
    BCol,
    BMedia,
    AppCollapse,
    AppCollapseItem,
  },
  directives: {
    "b-toggle": VBToggle,
    Ripple,
  },
  computed: {
    tryoutDetail() {
      return this.$store.state.tryout.detail;
    },
  },
  data() {
    return {
      totalTimer: 0,
      data: {},
      dataSubtes: [],
      jadwal_id: null,
      hasilRasionalisasi: {},
      is_lihat_hasil: false,
      loading: false,
    };
  },
  methods: {
    getData() {
      this.loading = true;
      let id = this.tryoutDetail.id;
      this.$store
        .dispatch("paketTryout/detail", id)
        .then((res) => {
          this.loading = false;
          this.data = res.data;
          this.$store.commit("paketTryout/SET_DETAIL", res.data);
          this.getSubtes();
        })
        .catch((err) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error `,
              icon: "XIcon",
              variant: "danger",
              text: err.response.data.message,
            },
          });
        });
    },
    getSubtes() {
      let params = {
        paket_id: this.data.id,
      };
      this.$store
        .dispatch("ujian/subtesPaket", params)
        .then((res) => {
          this.dataSubtes = res.data.data;
          if (this.data.is_contain_subcat == 1) {
            if (this.dataSubtes[0].items[0] != null) {
              let jadwal_id = this.dataSubtes[0].items[0].jadwal_id;
              this.jadwal_id = jadwal_id;
            }
          } else {
            if (this.dataSubtes[0] != null) {
              let jadwal_id = this.dataSubtes[0].jadwal_id;
              this.jadwal_id = jadwal_id;
            }
          }

          this.totalTimer = this.getTotalTimer(this.dataSubtes);
          if (this.data.is_contain_subcat == 1) {
            const sumSubtes = this.dataSubtes.reduce((total, itemSubtes) => {
              if (itemSubtes.items && itemSubtes.items.length > 0) {
                total += itemSubtes.items.length;
              }
              return total;
            }, 0);

            let sumCompleteSubtes = 0;
            this.dataSubtes.forEach((itemSubtes, i) => {
              if (itemSubtes.items) {
                sumCompleteSubtes += itemSubtes.items.reduce((total, item) => {
                  if (item.status && item.status == "complete") {
                    total += 1;
                  }
                  return total;
                }, 0);
              }
            });
            this.is_lihat_hasil = sumSubtes == sumCompleteSubtes;
          } else {
            let sumSubtes = this.dataSubtes.length;
            let sumCompleteSubtes = 0;
            sumCompleteSubtes += this.dataSubtes.reduce((total, item) => {
              if (item.status && item.status == "complete") {
                total += 1;
              }
              return total;
            }, 0);
            this.is_lihat_hasil = sumSubtes == sumCompleteSubtes;
          }
          if (this.is_lihat_hasil == true) {
            this.getHasil();
          }
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error `,
              icon: "XIcon",
              variant: "danger",
              text: err.response.data.message,
            },
          });
        });
    },
    getTotalTimer(subtests) {
      let totalTimer = 0;
      subtests.forEach((item) => {
        if (item.items && item.items.length > 0) {
          const totalTimerSubtest = item.items.reduce((total, subtest) => (total += parseInt(subtest.timer)), 0);
          totalTimer += totalTimerSubtest;
        } else {
          totalTimer += parseInt(item.timer);
        }
      });

      return totalTimer;
    },
    mulaiUjian(dataUjian) {
      this.loading = true;
      let payload = {
        paket_id: this.tryoutDetail.id,
        jadwal_id: dataUjian.jadwal_id,
        ujian_id: dataUjian.ujian_id,
      };
      this.$store
        .dispatch("ujian/mulaiUjian", payload)
        .then((res) => {
          this.loading = false;
          this.$store.commit("ujian/SET_DETAIL_UJIAN", dataUjian);
          this.$store.commit("ujian/SET_CURRENT_UJIAN", res.data);
          window.location = "main-ujian";
          // this.$router.push({ name: "main-ujian" });
        })
        .catch((err) => {
          this.loading = false;
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: `Gagal `,
                icon: "XIcon",
                variant: "danger",
                text: err.response.data.message,
              },
            },
            {
              position: "bottom-right",
            }
          );
        });
    },
    getHasil() {
      let params = {
        paket_id: this.data.id,
        jadwal_id: this.jadwal_id,
      };
      this.$store
        .dispatch("rasionalisasi/index", params)
        .then((res) => {
          this.hasilRasionalisasi = res.data;
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error `,
              icon: "XIcon",
              variant: "danger",
              text: err.response.data.message,
            },
          });
        });
    },
    lihatHasil() {
      this.$store.commit("ujian/SET_CURRENT_HASIL_RASIONALISASI", this.hasilRasionalisasi);
      this.$router.push({ name: "hasil-rasionalisasi" });
    },
  },
  created() {
    if (this.tryoutDetail.name == undefined) {
      this.$router.go(-1);
    }
    this.getData();
  },
};
</script>

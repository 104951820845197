var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('b-card', {
    staticClass: "overflow-hidden",
    attrs: {
      "bg-variant": "primary"
    }
  }, [_c('b-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('div', {}, [_c('h3', {
    staticClass: "text-white"
  }, [_vm._v(_vm._s(_vm.data.name))]), _c('div', {
    staticClass: "d-flex align-items-center mt-2"
  }, [_c('div', {
    staticClass: "d-flex align-items-center text-body ml-2"
  }, [_c('feather-icon', {
    staticClass: "mr-50 text-white",
    attrs: {
      "icon": "ClockIcon"
    }
  }), _c('small', {
    staticClass: "font-weight-bold text-white"
  }, [_vm._v("Waktu Berakhir")])], 1)]), _c('div', {
    staticClass: "d-flex align-items-center mt-1"
  }, [_c('div', {
    staticClass: "d-flex align-items-center text-body"
  }, [_c('small', {
    staticClass: "font-weight-bold text-white"
  }, [_vm._v(_vm._s(_vm.getTanggal(_vm.data.info_start)) + " WIB")])]), _c('div', {
    staticClass: "d-flex align-items-center text-body ml-1"
  }, [_c('small', {
    staticClass: "font-weight-bold text-white"
  }, [_vm._v(_vm._s(_vm.getTanggal(_vm.data.info_end)) + " WIB")])])])])]), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-card', [_c('b-row', {}, [_c('b-col', [_c('b-media', {
    staticClass: "mb-2",
    attrs: {
      "vertical-align": "top"
    },
    scopedSlots: _vm._u([{
      key: "aside",
      fn: function fn() {
        return [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "btn-icon rounded-circle",
          attrs: {
            "size": "sm",
            "variant": "dark",
            "disabled": ""
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "UserIcon"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('span', [_vm._v("Peserta")]), _vm._v(" "), _c('br'), _c('b', [_vm._v(_vm._s(_vm.data.sum_quota) + "/" + _vm._s(_vm.data.max_quota))])])], 1), _c('b-col', [_c('b-media', {
    staticClass: "mb-2",
    attrs: {
      "vertical-align": "top"
    },
    scopedSlots: _vm._u([{
      key: "aside",
      fn: function fn() {
        return [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "btn-icon rounded-circle",
          attrs: {
            "size": "sm",
            "variant": "dark",
            "disabled": ""
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "ClockIcon"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('span', [_vm._v("Total Waktu")]), _vm._v(" "), _c('br'), _c('b', [_vm._v(" " + _vm._s(_vm.totalTimer) + " menit")])])], 1), _c('b-col', [_c('b-media', {
    staticClass: "mb-2",
    attrs: {
      "vertical-align": "top"
    },
    scopedSlots: _vm._u([{
      key: "aside",
      fn: function fn() {
        return [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "btn-icon rounded-circle",
          attrs: {
            "size": "sm",
            "variant": "dark",
            "disabled": ""
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "FileTextIcon"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('span', [_vm._v("Total Soal")]), _vm._v(" "), _c('br'), _c('b', [_vm._v(_vm._s(_vm.data.sum_soal) + " Soal")])])], 1)], 1)], 1)], 1)], 1)], 1), _c('b-card', {
    attrs: {
      "header": 'Subtest(' + _vm.data.sum_subtest + ')',
      "header-text-variant": "black"
    }
  }, [_c('b-card-body', [_vm.dataSubtes[0] != null ? _c('div', {}, _vm._l(_vm.dataSubtes, function (data, i) {
    return _c('div', {
      key: i
    }, [data.name ? _c('app-collapse', [_c('app-collapse-item', {
      attrs: {
        "title": data.name
      }
    }, _vm._l(data.items, function (item, i) {
      return _c('div', {
        key: i,
        staticClass: "mt-2"
      }, [_c('b-card', {
        staticClass: "border",
        attrs: {
          "no-body": ""
        }
      }, [_c('b-card-body', [_c('div', {
        staticClass: "d-flex justify-content-between align-items-center"
      }, [_c('div', {
        staticClass: "align-items-center text-body"
      }, [_c('h4', [_c('b', [_vm._v(" " + _vm._s(item.nama_ujian))])]), item.jadwal_id != null ? _c('span', [item.hasil && item.status == 'complete' ? _c('span', [_c('feather-icon', {
        staticClass: "mr-50",
        attrs: {
          "icon": "CalendarIcon"
        }
      }), _c('small', {
        staticClass: "font-weight-bold"
      }, [_vm._v(_vm._s(_vm.getTanggal(item.start_time)) + " WIB s/d " + _vm._s(_vm.getTanggal(item.end_time)) + " WIB ")]), _c('div', {}, [_c('feather-icon', {
        staticClass: "mr-50 text-success",
        attrs: {
          "icon": "ClockIcon"
        }
      }), _c('small', {
        staticClass: "font-weight-bold"
      }, [_vm._v("Selesai")]), _c('feather-icon', {
        staticClass: "mr-50 ml-2 text-success",
        attrs: {
          "icon": "FileTextIcon"
        }
      }), _c('small', {
        staticClass: "font-weight-bold"
      }, [_vm._v("Selesai")])], 1)], 1) : _c('span', [_c('feather-icon', {
        staticClass: "mr-50",
        attrs: {
          "icon": "CalendarIcon"
        }
      }), _c('small', {
        staticClass: "font-weight-bold"
      }, [_vm._v(_vm._s(_vm.getTanggal(item.start_time)) + " WIB s/d " + _vm._s(_vm.getTanggal(item.end_time)) + " WIB ")]), _c('div', {}, [_c('feather-icon', {
        staticClass: "mr-50",
        attrs: {
          "icon": "ClockIcon"
        }
      }), _c('small', {
        staticClass: "font-weight-bold"
      }, [_vm._v(_vm._s(item.timer) + " Menit")]), _c('feather-icon', {
        staticClass: "mr-50 ml-2",
        attrs: {
          "icon": "FileTextIcon"
        }
      }), _c('small', {
        staticClass: "font-weight-bold"
      }, [_vm._v(_vm._s(item.sum_soal) + " Soal")])], 1)], 1)]) : _c('span', [_c('i', {
        staticClass: "text-danger"
      }, [_vm._v("Jadwal Belum ditentukan")])])]), _c('div', {
        staticClass: "d-flex align-items-center text-body"
      }, [item.jadwal_id ? _c('span', [item.hasil && item.status == 'complete' ? _c('b-button', {
        attrs: {
          "variant": "outline-success"
        }
      }, [_vm._v("Selesai")]) : !item.in_schedule ? _c('b-button', {
        attrs: {
          "variant": "outline-secondary",
          "disabled": ""
        }
      }, [_vm._v("Tidak Masuk Jadwal")]) : _c('b-button', {
        attrs: {
          "variant": "outline-primary"
        },
        on: {
          "click": function click($event) {
            return _vm.mulaiUjian(item);
          }
        }
      }, [_vm._v(" Mulai ")])], 1) : _c('span', [_c('b-button', {
        attrs: {
          "variant": "primary disabled",
          "disabled": ""
        }
      }, [_vm._v(" Jadwal Belum Ditentukan ")])], 1)])])])], 1)], 1);
    }), 0)], 1) : _c('b-card', {
      staticClass: "border",
      attrs: {
        "no-body": ""
      }
    }, [_c('b-card-body', [_c('div', {
      staticClass: "d-flex justify-content-between align-items-center"
    }, [_c('div', {
      staticClass: "align-items-center text-body"
    }, [_c('h4', [_c('b', [_vm._v(" " + _vm._s(data.nama_ujian))])]), data.jadwal_id != null ? _c('span', [data.status == 'complete' ? _c('span', [_c('feather-icon', {
      staticClass: "mr-50",
      attrs: {
        "icon": "CalendarIcon"
      }
    }), _c('small', {
      staticClass: "font-weight-bold"
    }, [_vm._v(_vm._s(_vm.getTanggal(data.start_time)) + " WIB s/d " + _vm._s(_vm.getTanggal(data.end_time)) + " WIB ")]), _c('div', {}, [_c('feather-icon', {
      staticClass: "mr-50 text-success",
      attrs: {
        "icon": "ClockIcon"
      }
    }), _c('small', {
      staticClass: "font-weight-bold"
    }, [_vm._v("Selesai")]), _c('feather-icon', {
      staticClass: "mr-50 ml-2 text-success",
      attrs: {
        "icon": "FileTextIcon"
      }
    }), _c('small', {
      staticClass: "font-weight-bold"
    }, [_vm._v("Selesai")])], 1)], 1) : _c('span', [_c('feather-icon', {
      staticClass: "mr-50",
      attrs: {
        "icon": "CalendarIcon"
      }
    }), _c('small', {
      staticClass: "font-weight-bold"
    }, [_vm._v(_vm._s(_vm.getTanggal(data.start_time)) + " WIB s/d " + _vm._s(_vm.getTanggal(data.end_time)) + " WIB ")]), _c('div', {}, [_c('feather-icon', {
      staticClass: "mr-50",
      attrs: {
        "icon": "ClockIcon"
      }
    }), _c('small', {
      staticClass: "font-weight-bold"
    }, [_vm._v(_vm._s(data.timer) + " Menit")]), _c('feather-icon', {
      staticClass: "mr-50 ml-2",
      attrs: {
        "icon": "FileTextIcon"
      }
    }), _c('small', {
      staticClass: "font-weight-bold"
    }, [_vm._v(_vm._s(data.sum_soal) + " Soal")])], 1)], 1)]) : _c('span', [_c('i', {
      staticClass: "text-danger"
    }, [_vm._v("Jadwal Belum ditentukan")])])]), _c('div', {
      staticClass: "d-flex align-items-center text-body"
    }, [data.jadwal_id != null ? _c('span', [data.status == 'complete' ? _c('b-button', {
      attrs: {
        "variant": "outline-success"
      }
    }, [_vm._v("Selesai")]) : _c('b-button', {
      attrs: {
        "variant": "outline-primary"
      },
      on: {
        "click": function click($event) {
          return _vm.mulaiUjian(data);
        }
      }
    }, [_vm._v(" Mulai ")])], 1) : _c('span', [_c('b-button', {
      attrs: {
        "variant": "primary disabled",
        "disabled": ""
      }
    }, [_vm._v(" Jadwal Belum Ditentukan ")])], 1)])])])], 1)], 1);
  }), 0) : _c('div', [_c('h5', {
    staticClass: "text-danger text-center mt-2"
  }, [_vm._v("Tidak ada Subtest")])]), _c('center', [_c('span', [_vm.is_lihat_hasil ? _c('b-button', {
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": function click($event) {
        return _vm.lihatHasil();
      }
    }
  }, [_vm._v(" Lihat Hasil ")]) : _vm._e()], 1)])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }